/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './src/aws-exports';
import { setUser } from './src/utils/auth';

Amplify.configure(awsconfig);

export const onRouteUpdate = (state, page, pages) => {
	Auth.currentAuthenticatedUser()
		.then((user) => {
			const userInfo = {
				...user.attributes,
				username: user.username,
			};
			setUser(userInfo);
		})
		.catch((err) => {
			window.localStorage.setItem('gatsbyUser', null);
		});
};
